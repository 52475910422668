import $ from "../../../../../../node_modules/cash-dom/dist/cash.esm.js";

$("form.general-form button[type='submit']").on("click", function showValidationErrors(evt) {
  var $button = $(this);
  var $form = $button.closest("form");
  var invalidElements = $form.find(":invalid");

  $form.find(".invalid").removeClass("invalid");

  if (invalidElements.length > 0) {
    evt.preventDefault();
    let firstElement = invalidElements[0];

    invalidElements.each((idx, element) => {
      $(element).closest(".field-group").addClass("invalid");
    });

    // firstElement.focus();
    // firstElement.scrollIntoView({
    //   behavior: "smooth",
    //   block: "center"
    // });

  }
});