import "../../../../node_modules/highcharts/highcharts.src.js";
// Load the data module that can parse data from table, csv, Google Sheets,...
import "../../../../node_modules/highcharts/modules/data.src.js";
import "../../../../node_modules/highcharts/modules/exporting.src.js";
import "../../../../node_modules/highcharts/modules/export-data.src.js";
import "../../../../node_modules/highcharts/modules/accessibility.src.js";

const VALUE_REGEX = /([$])?([\d]+)([%])?/;

// Set global options as a side-effect.
Highcharts.setOptions({
  chart: {
    styledMode: true,
  },
  title: {
    useHTML: true,
  },
  plotOptions: {
    // General options for all series
    series: {
      dataLabels: {
        enabled: true,
        formatter: function() {
          // Use the custom `point.text` from parsing to format the data label.
          return this.point.text;
        },
      },
    },
    pie: {
      // Allow this series' points to be selected by clicking on the graphic
      // (columns, point markers, pie slices, map areas etc).
      // The selected points can be handled by point select and unselect events,
      // or collectively by the `getSelectedPoints` function.
      allowPointSelect: true,
      cursor: "pointer",
      // Since 2.1, pies are not shown in the legend by default. However,
      // we set the data text as labels, so we need to show the series type
      // in legend.
      showInLegend: true,
    },

  },
  tooltip: {
    // Use the custom `point.text` from parsing to render the point's tooltip.
    pointFormat: `<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.text}</b><br/>`,
  },
  credits: {
    enabled: false,
  }
});

/** A chart representing tabular data. */
class Chart extends Highcharts.Chart {
  /**
   * Creates a chart from a HTMLTableELement with tabular data.
   *
   * @param {HTMLTableElement} table - The table containing tabular data.
   * @param {Highcharts.Options} [options] - Extra options.
   * @param {Highcharts.ChartCallbackFunction} [callback]
   *
   * The <caption> inside the <table>, if exists, becomes the chart's title.
   *
   * This class is different in which the container to insert the chart into
   * must be speficied in `options.chart.renderTo` if the chart needs to be
   * displayed in the DOM.
   */
  constructor(table, options = {}, callback) {
    const title = table.caption?.innerHTML;
    /** @type string[][] */
    const series = [];

    super({
      title: {
        // Set title's text from table's <caption>.
        text: title,
      },
      exporting: {
        // Also needs to set it here so HTML can be rendered on display.
        tableCaption: title,
      },
      data: {
        table,
        // Callback after the data are parsed but not yet used to render the chart.
        // We need to strip out any prefix and/or suffix so Highchart can take the numeric value.
        parsed(/** @type {Highcharts.DataValueType[][]} **/parsedColumns) {
          parsedColumns.forEach((column, columnIndex) => {
            // In here, the first column contains the points' name.
            if (columnIndex === 0) {
              return;
            }

            // Collecting our own data for mapping to points later.
            const rawData = [];
            series.push(rawData);

            column.forEach((cell, index) => {
              // First cell is the name of the point, not data.
              if (index > 0) {
                rawData.push(cell);
              }

              // Check for possible prefix and/or suffix.
              const [, valuePrefix = "", numeric, valueSuffix = ""] = `${ cell }`.match(VALUE_REGEX) || [];
              if (numeric) {
                column[index] = parseFloat(numeric);
              } else {
                column[index] = cell;
              }
            });
          });

          return undefined;
        },
        // Callback that is evaluated when the data is finished loading and parsed.
        // We use this to convert series data to points with custom text value.
        complete(chartOptions) {
          // Because we stripped out prefix and/or suffix from parsed data,
          // we need to retrieve back the actual text content to associate with
          // each points in the series. This custom `text` property will be used
          // in data labels and tooltip.
          chartOptions.series.forEach(function(seriesOptions, index) {
            const seriesData = series[index];
            const points = [];
            seriesOptions.data.forEach(function([ name, value ], pointIndex) {
              points.push({
                name,
                y: value,
                text: seriesData[pointIndex],
              });
            });

            seriesOptions.data = points;
          });

        },
      },
      ...options,
    }, (chart) => {
      // Use small delay for the modules to fully register.
      setTimeout(function() {
        // Shows the data in a table below the chart.
        // Requires Export-data module.
        chart.viewData();
        // Then hides it immediately so the user does not see it,
        // but the `<table>` remains in the DOM for the FDO to use.
        // Requires Export-data module.
        chart.hideData();

        const numberCells = chart.dataTableDiv.querySelectorAll("td.number");
        const seriesCount = series.length;

        numberCells.forEach(function(cell, index) {
          // Figure out corresponding indices with some math.
          const seriesIndex = index % seriesCount;
          const pointIndex = Math.floor(index / seriesCount);
          const text = series[seriesIndex][pointIndex];
          // Set the cell's text to the series' text.
          cell.textContent = text;
        });
      }, 0);

      callback && callback(chart);
    });
  }
}

// Renders all the charts on the page.
document.querySelectorAll("[is$=-chart]").forEach(container => {
  const type = container.getAttribute("is")?.replace("-chart", "");
  if (!type) {
    return;
  }

  const table = container.querySelector("table");
  const caption = container.querySelector("[slot=\"caption\"]");

  new Chart(table, {
    chart: {
      renderTo: container,
      type,
    },
    caption: {
      text: caption?.innerHTML,
    },
  });
});

window.Chart = Chart;

export { Chart };