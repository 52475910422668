import A11YSlider from "../../../../../../node_modules/a11y-slider/dist/a11y-slider.esm.js";
// Ensures the Tabs JS is loaded to handle the slide picker controls.
import "../../../tabs/index.js";

const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

class Carousel extends A11YSlider {
  constructor(component, options = {}) {
    const viewport = $(".carousel__viewport", component);
    const prevButtons = $$(".carousel__prev", component);
    const nextButtons = $$(".carousel__next", component);
    const tablist = $(`[role="tablist"`, component);
    const activeSlide = viewport.querySelector(`.carousel__slide:not([aria-hidden="true"])`);

    super(viewport, {
      // We already handle the markup in template, so we don't need extra container.
      container: false,
      arrows: false,
      // Use own previous arrow.
      prevArrow: prevButtons,
      // Use own next arrow.
      nextArrow: nextButtons,
      // Our carousel implements tab pattern, so we don't want this library's dots.
      dots: false,
      skipBtn: false,
    });

    this.onNextSlide = this.onNextSlide.bind(this);
    this.tablist = tablist;

    prevButtons.forEach(button => {
      button.addEventListener("click", this.onNextSlide);
    });

    nextButtons.forEach(button => {
      button.addEventListener("click", this.onNextSlide);
    });

    // Handles "tab" change to scroll to the slide.
    tablist.addEventListener("change", event => {
      this.scrollToSlide(event.detail.currentIndex);
    }, { passive: true });

    // Slides to slide specified in hash when page loads if any.
    if (window.location.hash) {
      this.scrollToSlide();
    } else {
      if (activeSlide) {
        setTimeout(() => {
          this.scrollToSlide(activeSlide);
        }, 0);
      }
    }
  }

  /**
   * Extends scrollToSlide to support scrolling by hash.
   * @param {string|Number|Node} slide The slide to scroll to, either specified by its
   * hash, its index, or itself.
   * @override
   */
  scrollToSlide(slide = window.location.hash) {
    if (typeof slide === "string" && slide.substring(1)) {
      slide = $(slide);
    }
    super.scrollToSlide(slide);
  }

  onNextSlide(event) {
    event.preventDefault();
    const hash = event.target.hash;
    const tab = this.tablist.querySelector(`a[role="tab"][href="${ hash }"]`);
    if (tab) {
      tab.click();
    }
  }
}

$$(".carousel").forEach(carousel => {
  new Carousel(carousel);
});
