const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

class AnchorNav {
  constructor(component, options = {}) {
    const buttons = $$("button", component);
    const navLinks = $$("ul a", component);

    this.toggleNav = this.toggleNav.bind(this);
    this.swapLabel = this.swapLabel.bind(this);
    this.clickLink = this.clickLink.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.component = component;
    this.nav = $(".anchor-nav__list", component);
    this.label = $(".anchor-nav__label", component);
    this.anchors = document.querySelectorAll("a[id]");

    navLinks.forEach(link => {
      link.addEventListener("click", this.clickLink);
    });

    buttons.forEach(button => {
      button.addEventListener("click", this.toggleNav);
    });

    const observer = new IntersectionObserver(
      this.handleIntersection.bind(this),
      {
        root: null,
        rootMargin: `15% 0% -85% 0%`,
        threshold: 0
      }
    );

    this.lastAnchorId = ``;
    this.anchors.forEach((element) => {
      const id = element.id;
      if (component.querySelector(`a[href^="#${ id }"]`)) {
        observer.observe(element);
      }
    });
  }

  handleIntersection(entries, _observer) {
    let { component } = this;
    entries.forEach(({ target, isIntersecting, intersectionRatio }) => {
      const id = target.id;
      const link = component.querySelector(`a[href^="#${ id }"]`);

      if (isIntersecting && intersectionRatio === 1) {
        this.label.innerText = link.innerText;
      }
    });
  }

  clickLink(event) {
    event.preventDefault();
    const id = event.target.getAttribute("href");
    this.scrollTo(id);
    this.swapLabel(id);
  }

  swapLabel(id) {
    const text = $(`a[href="${id}"]`, this.nav).text;
    this.label.innerText = text;
  }

  toggleNav(event) {
    event.preventDefault();
    if (this.nav.classList.contains("hidden")) {
      this.nav.classList.remove("hidden");
    } else {
      this.nav.classList.add("hidden");
    }
  }

  scrollTo(id) {
    let $el = $(id);
    let $header = $("header[role='banner']");
    let $anchorNav = $(".anchor-nav");
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop

    if (!$el) {
      return;
    }

    let headerOffset = 0 + $header.offsetHeight + $anchorNav.offsetHeight;
    if ($("body").classList.contains("samadmin")) {
      headerOffset = headerOffset + 97;
    }

    window.scroll({
      top: $el.getBoundingClientRect()["top"] + scrollTop
            - headerOffset
            - 45 /* Extra padding for visual look */,
      behavior: 'smooth'
    });
  };
}

$$(".anchor-nav").forEach(anchorNav => {
  new AnchorNav(anchorNav);
});

export default AnchorNav;
