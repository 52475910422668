// Vendor Libraries
import $ from "../../node_modules/cash-dom/dist/cash.esm.js";

if(window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}
if(window.HTMLCollection && !HTMLCollection.prototype.forEach) {
  HTMLCollection.prototype.forEach = Array.prototype.forEach;
}

// Other imports (collections, entities, ...)
import "./imports.js";

// Application's Libraries.
import "./components/modals.js";
import "./components/search.js";

$(function documentReadyHandler() {
  console.log("Document Ready!");
}, document);
