// Vendor Libraries
import $ from "../../../../../../node_modules/cash-dom/dist/cash.esm.js";
let $document = $(document);

// Primary nav handlers
$document.on(`mouseover`, `#menu-toggle:not(:checked) + .page-header nav > ul > .isParent`, function() {
  $(this).addClass(`open`);
});
$document.on(`mouseout`, `#menu-toggle:not(:checked) + .page-header nav > ul > .isParent`, function() {
  $(this).removeClass(`open`);
});

$document.on(`click`, `.page-header a + button`, function() {
  let $toggle = $(this);
  let $navItem = $toggle.closest(`li`);
  let $otherNavItems = $navItem.siblings();
  // Reset other items.
  $otherNavItems.removeClass(`open`);
  $otherNavItems.children(`button`).attr(`aria-expanded`, `false`);

  $navItem.toggleClass(`open`);
  if ($toggle.attr(`aria-expanded`) === `true`) {
    $toggle.attr(`aria-expanded`, `false`);
  } else {
    $toggle.attr(`aria-expanded`, `true`);
  }
});

$document.on(`click`, `#menu-toggle:checked + .page-header nav .isParent > a`, function(event) {
  const $navItem = $(this);
  const $parent = $navItem.parent();

  if (!$navItem.siblings(`ul`).length) {
    return;
  }

  event.preventDefault();

  if ($parent.hasClass(`open`)) {
    $parent.removeClass(`open`);
  } else {
    $parent.addClass(`open`).siblings().removeClass(`open`);
  }
});